import React, { Component } from "react";
import { fetchData } from "./AwsFunctions.js";
import "./App.css";
import * as AWS from "aws-sdk";
import Main from "./components/Main";

class App extends Component {
  async GetDataFromAws() {
    return await fetchData(
      "https://lw1tyg25lc.execute-api.us-west-1.amazonaws.com/Production/id/0"
    )
      .then((x) => x.json())
      .then((x) => {
        const unmarshalledData = AWS.DynamoDB.Converter.unmarshall(x.Items[0]);
        this.setState({ DbSettings: unmarshalledData });
      });
  }

  async GetAllowedDataFromAws() {
    return await fetchData(
      "https://agjqn1mice.execute-api.us-west-1.amazonaws.com/Production/id/0"
    )
      .then((x) => x.json())
      .then((x) => {
        const unmarshalledData = AWS.DynamoDB.Converter.unmarshall(x.Items[0]);
        this.setState({ AllowedDbSettings: unmarshalledData });
      });
  }

  constructor(props) {
    super(props);
    this.state = {
      DbSettings: [],
      AllowedDbSettings: [],
    };
  }

  componentDidMount() {
    this.GetDataFromAws();
    this.GetAllowedDataFromAws();
  }

  render() {
    if (
      this.state.DbSettings.length == 0 ||
      this.state.AllowedDbSettings.length == 0
    ) {
      return <div className="mx-auto" />;
    }

    return (
      <div style={{ height: "100vh" }} className="mx-auto bg-dark">
        <Main
          DbSettings={this.state.DbSettings}
          AllowedDbSettings={this.state.AllowedDbSettings}
        />
      </div>
    );
  }
}

export default App;
