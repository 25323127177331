import React, { useImperativeHandle, useState } from "react";

const FileUploader = React.forwardRef(({ id, label }, ref) => {
  const [selectedFile, setSelectedFile] = useState(null);
  useImperativeHandle(
    ref,
    () => ({
      getMyState: () => {
        return selectedFile;
      },
    }),
    [selectedFile]
  );

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        className="form-control"
        type="file"
        id={id}
        onChange={handleFileInput}
      />
    </div>
  );
});

export default FileUploader;
