import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Body from "./Body/Body";
import Login from "./Login/Login";

const Main = ({ DbSettings, AllowedDbSettings }) => {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <Routes>
      <Route exact path="/" element={<Login setAuth={setAuthenticated} />} />
      <Route
        exact
        path="/settings"
        element={
          <Body
            DbSettings={DbSettings}
            AllowedDbSettings={AllowedDbSettings}
            Authenticated={authenticated}
          />
        }
      />
    </Routes>
  );
};

export default Main;
