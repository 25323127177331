import React, { useRef, useState } from "react";
import "./Body.css";
import FileUploader from "./FileUploader/FileUploader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as AWS from "aws-sdk";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const Body = ({ DbSettings, AllowedDbSettings, Authenticated }) => {
  const navigate = useNavigate();

  const desktopIconRef = useRef();
  const mobileIconRef = useRef();
  const aboutMeImg1Ref = useRef();
  const aboutMeImg2Ref = useRef();
  const aboutMeImg3Ref = useRef();

  const [dbSettings, setDbSettings] = useState(DbSettings);
  const [allowedDbSettings] = useState(AllowedDbSettings);

  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const saveToDb = () => {
    handleClose();

    let tmpDbSettings = dbSettings;

    const desktopIconFile = desktopIconRef.current.getMyState();
    const mobileIconFile = mobileIconRef.current.getMyState();
    const aboutMeImg1File = aboutMeImg1Ref.current.getMyState();
    const aboutMeImg2File = aboutMeImg2Ref.current.getMyState();
    const aboutMeImg3File = aboutMeImg3Ref.current.getMyState();

    if (desktopIconFile != null) {
      let url;
      url = uploadFileToS3(desktopIconFile);
      tmpDbSettings.NavBar.DesktopLogoUrl = url;
    }

    if (mobileIconFile != null) {
      let url;
      url = uploadFileToS3(mobileIconFile);
      tmpDbSettings.NavBar.MobileLogoUrl = url;
    }

    if (aboutMeImg1File != null) {
      let url;
      url = uploadFileToS3(aboutMeImg1File);
      tmpDbSettings.Pages[3].TextSections[0].ImageUrl = url;
    }

    if (aboutMeImg2File != null) {
      let url;
      url = uploadFileToS3(aboutMeImg2File);
      tmpDbSettings.Pages[3].TextSections[1].ImageUrl = url;
    }

    if (aboutMeImg3File != null) {
      let url;
      url = uploadFileToS3(aboutMeImg3File);
      tmpDbSettings.Pages[3].TextSections[2].ImageUrl = url;
    }

    var params = {
      TableName: "Content_Settings",
      Key: {
        Id: 0,
      },
      UpdateExpression: "set NavBar = :NavBar, Pages = :Pages",
      ExpressionAttributeValues: {
        ":NavBar": tmpDbSettings.NavBar,
        ":Pages": tmpDbSettings.Pages,
      },
    };

    var data = {
      UserPoolId: "us-west-1_Xudw7DSk9",
      ClientId: "54i77ivu68p7e12t6e0vuft9e7",
    };

    var userPool = new AmazonCognitoIdentity.CognitoUserPool(data);

    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log(err);
          return;
        }

        AWS.config.region = REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: "us-west-1:b6bfc132-5a63-4ffc-8c23-5a1234cad821",
          Logins: {
            "cognito-idp.us-west-1.amazonaws.com/us-west-1_Xudw7DSk9": session
              .getIdToken()
              .getJwtToken(),
          },
        });

        AWS.config.credentials.get(function (err) {
          if (!err) {
            var docClient = new AWS.DynamoDB.DocumentClient({
              region: REGION,
              credentials: AWS.config.credentials,
            });

            docClient.update(params).send((err) => {
              if (err) {
                toast.error("Something went wrong, please try again");
                console.log(err);
              } else {
                toast.success("Website Updated");
                setDbSettings(tmpDbSettings);
              }
            });
          }
        });
      });
    }
  };

  const pages = dbSettings.Pages;
  const homePage = pages[0];
  const servicesPage = pages[1];
  const aboutMePage = pages[3];
  const contactPage = pages[2];

  const updateDropdownColor = (id, oldColor, newColor) => {
    document.getElementById(id).classList.remove(`bg-${oldColor}`);
    document.getElementById(id).classList.add(`bg-${newColor}`);

    let textColor;
    if (newColor == "black" || newColor == "dark") {
      textColor = "light";
    } else {
      textColor = "dark";
    }

    document.getElementById(id).classList.remove("text-light");
    document.getElementById(id).classList.remove("text-dark");
    document.getElementById(id).classList.add(`text-${textColor}`);
  };

  const handleCardsBackgroundColorChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[0].Cards[0].BackgroundColor;
    tmpDbSettings.Pages[0].Cards.forEach((card) => {
      card.BackgroundColor = e.target.value;
    });

    updateDropdownColor(
      "cardBackgroundColorFormInput",
      oldColor,
      e.target.value
    );

    setDbSettings(tmpDbSettings);
  };

  const handleCardsBorderColorChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[0].Cards[0].BorderColor;
    tmpDbSettings.Pages[0].Cards.forEach((card) => {
      card.BorderColor = e.target.value;
    });

    updateDropdownColor("cardBorderColorFormInput", oldColor, e.target.value);

    setDbSettings(tmpDbSettings);
  };

  const handleSectionsBackgroundColorChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[1].Sections[0].SectionBackground;
    tmpDbSettings.Pages[1].Sections.forEach((section) => {
      section.SectionBackground = e.target.value;
    });

    updateDropdownColor(
      "sectionsBackgroundColorFormInput",
      oldColor,
      e.target.value
    );

    setDbSettings(tmpDbSettings);
  };

  const handleSectionsBorderColorChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[1].Sections[0].BorderColor;
    tmpDbSettings.Pages[1].Sections.forEach((section) => {
      section.BorderColor = e.target.value;
    });

    updateDropdownColor(
      "sectionsBorderColorFormInput",
      oldColor,
      e.target.value
    );

    setDbSettings(tmpDbSettings);
  };

  const handleSectionsHeaderForegroundChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[1].Sections[0].HeaderForeground;
    tmpDbSettings.Pages[1].Sections.forEach((section) => {
      section.HeaderForeground = e.target.value;
    });

    updateDropdownColor(
      "sectionsHeaderColorFormInput",
      oldColor,
      e.target.value
    );

    setDbSettings(tmpDbSettings);
  };

  const handleSectionsContentForegroundChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[1].Sections[0].SectionForeground;
    tmpDbSettings.Pages[1].Sections.forEach((section) => {
      section.SectionForeground = e.target.value;
    });

    updateDropdownColor(
      "sectionsContentForegroundFormInput",
      oldColor,
      e.target.value
    );

    setDbSettings(tmpDbSettings);
  };

  const handleSectionsIconColorChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[1].Sections[0].IconColor;
    tmpDbSettings.Pages[1].Sections.forEach((section) => {
      section.IconColor = e.target.value;
    });

    updateDropdownColor("sectionsIconColorFormInput", oldColor, e.target.value);

    setDbSettings(tmpDbSettings);
  };

  const handleSectionHeaderTextChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const sectionIndex = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : 2;
    tmpDbSettings.Pages[1].Sections[sectionIndex].HeaderContent =
      e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleSectionIconNameChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const sectionIndex = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : 2;
    tmpDbSettings.Pages[1].Sections[sectionIndex].IconName = e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleSectionBulletPointChanged = (e) => {
    let tmpDbSettings = dbSettings;

    const indexes = GetSectionAndBulletPointIndex(e.target.id);

    tmpDbSettings.Pages[1].Sections[indexes.sectionIndex].BulletPoints[
      indexes.bulletPointIndex
    ] = e.target.value;

    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const addNewBulletPoint = (e) => {
    e.preventDefault();

    let tmpDbSettings = dbSettings;

    const sectionIndex = e.target.id.substring(7, 8);
    tmpDbSettings.Pages[1].Sections[sectionIndex].BulletPoints.push(
      new String()
    );

    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleBulletPointRemoveClicked = (e) => {
    e.preventDefault();

    let tmpDbSettings = dbSettings;

    const indexes = GetSectionAndBulletPointIndex(e.target.id);

    if (
      tmpDbSettings.Pages[1].Sections[indexes.sectionIndex].BulletPoints
        .length == 1
    ) {
      toast.error("You must have at least 1 bullet point per section.");
    } else {
      const tmpBulletPoint =
        tmpDbSettings.Pages[1].Sections[indexes.sectionIndex].BulletPoints[
          indexes.bulletPointIndex
        ];

      tmpDbSettings.Pages[1].Sections[indexes.sectionIndex].BulletPoints =
        tmpDbSettings.Pages[1].Sections[
          indexes.sectionIndex
        ].BulletPoints.filter((x) => x != tmpBulletPoint);

      setDbSettings({ ...dbSettings, ...tmpDbSettings });
    }
  };

  const handleTextSectionHeaderChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const index = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : 2;
    tmpDbSettings.Pages[3].TextSections[index].Header = e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleTextSectionTextChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const index = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : 2;
    tmpDbSettings.Pages[3].TextSections[index].Text = e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleTextIconNameChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const index = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : 2;
    tmpDbSettings.Pages[3].TextSections[index].IconName = e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleTextImgAltChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const index = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : 2;
    tmpDbSettings.Pages[3].TextSections[index].ImageAlt = e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleContactIconNameChanged = (e) => {
    let tmpDbSettings = dbSettings;
    tmpDbSettings.Pages[2].Icon = e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleContactIconCaptionChanged = (e) => {
    let tmpDbSettings = dbSettings;
    tmpDbSettings.Pages[2].Content = e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const GetSectionAndBulletPointIndex = (id) => {
    const sectionIndex = id.substring(7, 8);
    const bulletPointIndex = id.substring(19, 20);

    return { sectionIndex: sectionIndex, bulletPointIndex: bulletPointIndex };
  };

  const handleCardsButtonColorChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[0].Cards[0].ButtonForeground;
    tmpDbSettings.Pages[0].Cards.forEach((card) => {
      card.ButtonForeground = e.target.value;
    });

    updateDropdownColor("cardButtonColorFormInput", oldColor, e.target.value);

    setDbSettings(tmpDbSettings);
  };

  const handleCardsTextColorChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[0].Cards[0].ContentColor;
    tmpDbSettings.Pages[0].Cards.forEach((card) => {
      card.ContentColor = e.target.value;
    });

    updateDropdownColor("cardTextColorFormInput", oldColor, e.target.value);

    setDbSettings(tmpDbSettings);
  };

  const handleSubCardsBorderColorChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[0].SubCards[0].BorderColor;
    tmpDbSettings.Pages[0].SubCards.forEach((card) => {
      card.BorderColor = e.target.value;
    });

    updateDropdownColor(
      "subCardBorderColorFormInput",
      oldColor,
      e.target.value
    );

    setDbSettings(tmpDbSettings);
  };

  const handleSubCardsTextColorChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const oldColor = dbSettings.Pages[0].SubCards[0].ContentColor;
    tmpDbSettings.Pages[0].SubCards.forEach((card) => {
      card.ContentColor = e.target.value;
    });

    updateDropdownColor("subCardTextColorFormInput", oldColor, e.target.value);

    setDbSettings(tmpDbSettings);
  };

  const handleCardTextChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const cardIndex = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : e.target.id.includes("2")
      ? 2
      : 3;
    tmpDbSettings.Pages[0].Cards[cardIndex].Text = e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleCardIconChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const cardIndex = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : e.target.id.includes("2")
      ? 2
      : 3;
    tmpDbSettings.Pages[0].Cards[cardIndex].IconName = e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleCardLinkToChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const cardIndex = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : e.target.id.includes("2")
      ? 2
      : 3;
    tmpDbSettings.Pages[0].Cards[cardIndex].linkTo = e.target.value;
    switch (e.target.value) {
      case "/":
        tmpDbSettings.Pages[0].Cards[cardIndex].linkId = "homeLink";
        break;
      case "/services":
        tmpDbSettings.Pages[0].Cards[cardIndex].linkId = "servicesLink";
        break;
      case "/about-me":
        tmpDbSettings.Pages[0].Cards[cardIndex].linkId = "aboutMeLink";
        break;
      case "/contact":
        tmpDbSettings.Pages[0].Cards[cardIndex].linkId = "contactBtn";
        break;
    }

    document.getElementById(`card${cardIndex}SectionIdDiv`).hidden =
      dbSettings.Pages[0].Cards[cardIndex].linkId != "servicesLink";

    if (document.getElementById(`card${cardIndex}SectionIdDiv`).hidden) {
      tmpDbSettings.Pages[0].Cards[cardIndex].SectionId = -1;
    }

    setDbSettings(tmpDbSettings);
  };

  const handleCardServicesSectionIdChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const cardIndex = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : e.target.id.includes("2")
      ? 2
      : 3;
    tmpDbSettings.Pages[0].Cards[cardIndex].SectionId =
      servicesPage.Sections.indexOf(
        servicesPage.Sections.filter(
          (x) => x.HeaderContent == e.target.value
        )[0]
      );
    setDbSettings(tmpDbSettings);
  };

  const handleSubCardTextChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const subCardIndex = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : 2;
    tmpDbSettings.Pages[0].SubCards[subCardIndex].Content = e.target.value;
    setDbSettings({ ...dbSettings, ...tmpDbSettings });
  };

  const handleSubCardLinkToChanged = (e) => {
    let tmpDbSettings = dbSettings;
    const subCardIndex = e.target.id.includes("0")
      ? 0
      : e.target.id.includes("1")
      ? 1
      : 2;
    tmpDbSettings.Pages[0].SubCards[subCardIndex].Link = e.target.value;
    switch (e.target.value) {
      case "/":
        tmpDbSettings.Pages[0].SubCards[subCardIndex].linkId = "homeLink";
        break;
      case "services":
        tmpDbSettings.Pages[0].SubCards[subCardIndex].linkId = "servicesLink";
        break;
      case "about-me":
        tmpDbSettings.Pages[0].SubCards[subCardIndex].linkId = "aboutMeLink";
        break;
      case "contact":
        tmpDbSettings.Pages[0].SubCards[subCardIndex].linkId = "contactBtn";
        break;
    }

    setDbSettings(tmpDbSettings);
  };

  const S3_BUCKET = "tellerdevelopmentimages";
  const REGION = "us-west-1";

  const uploadFileToS3 = (file) => {
    const key = crypto.randomUUID();

    const params = {
      //ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: key,
    };

    var data = {
      UserPoolId: "us-west-1_Xudw7DSk9",
      ClientId: "54i77ivu68p7e12t6e0vuft9e7",
    };

    var userPool = new AmazonCognitoIdentity.CognitoUserPool(data);

    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log(err);
          return;
        }

        AWS.config.region = REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: "us-west-1:b6bfc132-5a63-4ffc-8c23-5a1234cad821",
          Logins: {
            "cognito-idp.us-west-1.amazonaws.com/us-west-1_Xudw7DSk9": session
              .getIdToken()
              .getJwtToken(),
          },
        });

        AWS.config.credentials.get(function (err) {
          if (!err) {
            const myBucket = new AWS.S3({
              params: { Bucket: S3_BUCKET },
              region: REGION,
              credentials: AWS.config.credentials,
            });

            myBucket.putObject(params).send((err) => {
              if (err) {
                toast.error(
                  "Something went wrong while uploading a file, please try again"
                );
              }
            });
          }
        });
      });
    }

    return `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${key}`;
  };

  const saveClicked = (e) => {
    e.preventDefault();
    handleShow();
  };

  const navbarSection = (
    <>
      <h2>NavBar</h2>
      <FileUploader
        ref={desktopIconRef}
        id="desktopIconFormInput"
        label="Logo for Large Screen"
      />
      <FileUploader
        ref={mobileIconRef}
        id="mobileIconFormInput"
        label="Logo for Small (Mobile) Screen"
      />
    </>
  );

  const pagesSection = (
    <>
      <h2 className="mt-3">Pages</h2>
      <div>
        <h3 className="mt-3">{homePage.Name}</h3>

        <div>
          <label htmlFor="cardBackgroundColorFormInput" className="form-label">
            Cards Background Color
          </label>
          <select
            id="cardBackgroundColorFormInput"
            className={`form-select bg-${
              homePage.Cards[0].BackgroundColor
            } text-${
              homePage.Cards[0].BackgroundColor == "black" ||
              homePage.Cards[0].BackgroundColor == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleCardsBackgroundColorChanged}
          >
            <option defaultValue={homePage.Cards[0].BackgroundColor}>
              {homePage.Cards[0].BackgroundColor}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != homePage.Cards[0].BackgroundColor
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="cardBorderColorFormInput" className="form-label">
            Cards Border Color
          </label>
          <select
            id="cardBorderColorFormInput"
            className={`form-select bg-${homePage.Cards[0].BorderColor} text-${
              homePage.Cards[0].BorderColor == "black" ||
              homePage.Cards[0].BorderColor == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleCardsBorderColorChanged}
          >
            <option defaultValue={homePage.Cards[0].BorderColor}>
              {homePage.Cards[0].BorderColor}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != homePage.Cards[0].BorderColor
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="cardButtonColorFormInput" className="form-label">
            Cards Button Color
          </label>
          <select
            id="cardButtonColorFormInput"
            className={`form-select bg-${
              homePage.Cards[0].ButtonForeground
            } text-${
              homePage.Cards[0].ButtonForeground == "black" ||
              homePage.Cards[0].ButtonForeground == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleCardsButtonColorChanged}
          >
            <option defaultValue={homePage.Cards[0].ButtonForeground}>
              {homePage.Cards[0].ButtonForeground}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != homePage.Cards[0].ButtonForeground
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="cardTextColorFormInput" className="form-label">
            Cards Text/Icon Color
          </label>
          <select
            id="cardTextColorFormInput"
            className={`form-select bg-${homePage.Cards[0].ContentColor} text-${
              homePage.Cards[0].ContentColor == "black" ||
              homePage.Cards[0].ContentColor == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleCardsTextColorChanged}
          >
            <option defaultValue={homePage.Cards[0].ContentColor}>
              {homePage.Cards[0].ContentColor}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != homePage.Cards[0].ContentColor
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="subCardBorderColorFormInput" className="form-label">
            SubCards Border Color
          </label>
          <select
            id="subCardBorderColorFormInput"
            className={`form-select bg-${
              homePage.SubCards[0].BorderColor
            } text-${
              homePage.SubCards[0].BorderColor == "black" ||
              homePage.SubCards[0].BorderColor == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleSubCardsBorderColorChanged}
          >
            <option defaultValue={homePage.SubCards[0].BorderColor}>
              {homePage.SubCards[0].BorderColor}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != homePage.SubCards[0].BorderColor
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="subCardTextColorFormInput" className="form-label">
            SubCards Text Color
          </label>
          <select
            id="subCardTextColorFormInput"
            className={`form-select bg-${
              homePage.SubCards[0].ContentColor
            } text-${
              homePage.SubCards[0].ContentColor == "black" ||
              homePage.SubCards[0].ContentColor == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleSubCardsTextColorChanged}
          >
            <option defaultValue={homePage.SubCards[0].ContentColor}>
              {homePage.SubCards[0].ContentColor}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != homePage.SubCards[0].ContentColor
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        {homePage.Cards.map((card) => (
          <div key={`subCard${homePage.Cards.indexOf(card)}`}>
            <h4 className="mt-3">Card {homePage.Cards.indexOf(card) + 1}</h4>
            <div>
              <label
                htmlFor={`card${homePage.Cards.indexOf(card)}TextFormInput`}
                className="form-label"
              >
                Text
              </label>
              <input
                type="text"
                className="form-control"
                id={`card${homePage.Cards.indexOf(card)}TextFormInput`}
                value={card.Text}
                onChange={handleCardTextChanged}
              />
            </div>
            <div>
              <label
                htmlFor={`card${homePage.Cards.indexOf(card)}IconFormInput`}
                className="form-label"
              >
                {"Icon (Click "}
                <a href="https://icons.getbootstrap.com/#icons" target="_blank">
                  here
                </a>
                {" for the list of available icons to use)"}
              </label>
              <input
                type="text"
                className="form-control"
                id={`card${homePage.Cards.indexOf(card)}IconFormInput`}
                value={card.IconName}
                onChange={handleCardIconChanged}
              />
            </div>
            <div>
              <label
                htmlFor={`card${homePage.Cards.indexOf(card)}LinkToFormInput`}
                className="form-label"
              >
                Link To (Note: / is the Home page)
              </label>
              <select
                id={`card${homePage.Cards.indexOf(card)}LinkToFormInput`}
                className="form-select"
                aria-label="Default select"
                onChange={handleCardLinkToChanged}
              >
                <option defaultValue={card.linkTo}>{card.linkTo}</option>
                {allowedDbSettings.AllowedLinks.filter(
                  (x) => x != card.linkTo && x != "/"
                ).map((link) => (
                  <option
                    key={allowedDbSettings.AllowedLinks.indexOf(link)}
                    value={link}
                  >
                    {link}
                  </option>
                ))}
              </select>
            </div>
            <div
              id={`card${homePage.Cards.indexOf(card)}SectionIdDiv`}
              hidden={card.linkTo != "/services"}
            >
              <label
                htmlFor={`card${homePage.Cards.indexOf(
                  card
                )}ServicesSectionIdFormInput`}
                className="form-label"
              >
                Section to scroll to when the button goes to the Services page
              </label>
              <select
                id={`card${homePage.Cards.indexOf(
                  card
                )}ServicesSectionIdFormInput`}
                className="form-select"
                aria-label="Default select"
                onChange={handleCardServicesSectionIdChanged}
              >
                <option
                  defaultValue={
                    card.SectionId != -1
                      ? servicesPage.Sections.filter(
                          (x) =>
                            servicesPage.Sections.indexOf(x) == card.SectionId
                        )[0].HeaderContent
                      : ""
                  }
                >
                  {card.SectionId != -1
                    ? servicesPage.Sections.filter(
                        (x) =>
                          servicesPage.Sections.indexOf(x) == card.SectionId
                      )[0].HeaderContent
                    : ""}
                </option>
                {servicesPage.Sections.filter(
                  (x) => servicesPage.Sections.indexOf(x) != card.SectionId
                ).map((section) => (
                  <option
                    key={servicesPage.Sections.indexOf(section)}
                    value={section.HeaderContent}
                  >
                    {section.HeaderContent}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ))}

        {homePage.SubCards.map((subCard) => (
          <div key={`subCard${homePage.SubCards.indexOf(subCard)}`}>
            <h4 className="mt-3">
              SubCard {homePage.SubCards.indexOf(subCard) + 1}
            </h4>
            <div>
              <label
                htmlFor={`subCard${homePage.SubCards.indexOf(
                  subCard
                )}TextFormInput`}
                className="form-label"
              >
                Text
              </label>
              <input
                type="text"
                className="form-control"
                id={`subCard${homePage.SubCards.indexOf(subCard)}TextFormInput`}
                value={
                  dbSettings.Pages[0].SubCards[
                    homePage.SubCards.indexOf(subCard)
                  ].Content
                }
                onChange={handleSubCardTextChanged}
              />
            </div>
            <div>
              <label
                htmlFor={`subCard${homePage.SubCards.indexOf(
                  subCard
                )}LinkToFormInput`}
                className="form-label"
              >
                Link To (Note: / is the Home page)
              </label>
              <select
                id={`subCard${homePage.SubCards.indexOf(
                  subCard
                )}LinkToFormInput`}
                className="form-select"
                aria-label="Default select"
                onChange={handleSubCardLinkToChanged}
              >
                <option
                  defaultValue={subCard.Link}
                >{`/${subCard.Link}`}</option>
                {allowedDbSettings.AllowedLinks.filter(
                  (x) => x != `/${subCard.Link}` && x != "/"
                ).map((link) => (
                  <option
                    key={allowedDbSettings.AllowedLinks.indexOf(link)}
                    value={link.substring(1)}
                  >
                    {link}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ))}
      </div>

      <div>
        <h3 className="mt-3">{servicesPage.Name}</h3>

        <div>
          <label
            htmlFor="sectionsBackgroundColorFormInput"
            className="form-label"
          >
            Sections Background Color
          </label>
          <select
            id="sectionsBackgroundColorFormInput"
            className={`form-select bg-${
              servicesPage.Sections[0].SectionBackground
            } text-${
              servicesPage.Sections[0].SectionBackground == "black" ||
              servicesPage.Sections[0].SectionBackground == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleSectionsBackgroundColorChanged}
          >
            <option defaultValue={servicesPage.Sections[0].SectionBackground}>
              {servicesPage.Sections[0].SectionBackground}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != servicesPage.Sections[0].SectionBackground
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="sectionsBorderColorFormInput" className="form-label">
            Sections Border Color
          </label>
          <select
            id="sectionsBorderColorFormInput"
            className={`form-select bg-${
              servicesPage.Sections[0].BorderColor
            } text-${
              servicesPage.Sections[0].BorderColor == "black" ||
              servicesPage.Sections[0].BorderColor == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleSectionsBorderColorChanged}
          >
            <option defaultValue={servicesPage.Sections[0].BorderColor}>
              {servicesPage.Sections[0].BorderColor}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != servicesPage.Sections[0].BorderColor
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="sectionsHeaderColorFormInput" className="form-label">
            Sections Header Color
          </label>
          <select
            id="sectionsHeaderColorFormInput"
            className={`form-select bg-${
              servicesPage.Sections[0].HeaderForeground
            } text-${
              servicesPage.Sections[0].HeaderForeground == "black" ||
              servicesPage.Sections[0].HeaderForeground == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleSectionsHeaderForegroundChanged}
          >
            <option defaultValue={servicesPage.Sections[0].HeaderForeground}>
              {servicesPage.Sections[0].HeaderForeground}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != servicesPage.Sections[0].HeaderForeground
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label
            htmlFor="sectionsContentForegroundFormInput"
            className="form-label"
          >
            Sections Text Color
          </label>
          <select
            id="sectionsContentForegroundFormInput"
            className={`form-select bg-${
              servicesPage.Sections[0].SectionForeground
            } text-${
              servicesPage.Sections[0].SectionForeground == "black" ||
              servicesPage.Sections[0].SectionForeground == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleSectionsContentForegroundChanged}
          >
            <option defaultValue={servicesPage.Sections[0].SectionForeground}>
              {servicesPage.Sections[0].SectionForeground}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != servicesPage.Sections[0].SectionForeground
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="sectionsIconColorFormInput" className="form-label">
            Sections Icon Color
          </label>
          <select
            id="sectionsIconColorFormInput"
            className={`form-select bg-${
              servicesPage.Sections[0].IconColor
            } text-${
              servicesPage.Sections[0].IconColor == "black" ||
              servicesPage.Sections[0].IconColor == "dark"
                ? "light"
                : "dark"
            }`}
            aria-label="Default select"
            onChange={handleSectionsIconColorChanged}
          >
            <option defaultValue={servicesPage.Sections[0].IconColor}>
              {servicesPage.Sections[0].IconColor}
            </option>
            {allowedDbSettings.AllowedColors.filter(
              (x) => x != servicesPage.Sections[0].IconColor
            ).map((color) => (
              <option
                key={allowedDbSettings.AllowedColors.indexOf(color)}
                value={color}
              >
                {color}
              </option>
            ))}
          </select>
        </div>

        {servicesPage.Sections.map((section) => (
          <div key={`section${servicesPage.Sections.indexOf(section)}`}>
            <h4 className="mt-3">
              Section {servicesPage.Sections.indexOf(section) + 1}
            </h4>

            <div>
              <label
                htmlFor={`section${servicesPage.Sections.indexOf(
                  section
                )}HeaderTextFormInput`}
                className="form-label"
              >
                Header Text
              </label>
              <input
                type="text"
                className="form-control"
                id={`section${servicesPage.Sections.indexOf(
                  section
                )}HeaderTextFormInput`}
                value={
                  dbSettings.Pages[1].Sections[
                    servicesPage.Sections.indexOf(section)
                  ].HeaderContent
                }
                onChange={handleSectionHeaderTextChanged}
              />
            </div>

            <div>
              <label
                htmlFor={`section${servicesPage.Sections.indexOf(
                  section
                )}IconNameFormInput`}
                className="form-label"
              >
                {"Icon (Click "}
                <a href="https://icons.getbootstrap.com/#icons" target="_blank">
                  here
                </a>
                {" for the list of available icons to use)"}
              </label>
              <input
                type="text"
                className="form-control"
                id={`section${servicesPage.Sections.indexOf(
                  section
                )}IconNameFormInput`}
                value={
                  dbSettings.Pages[1].Sections[
                    servicesPage.Sections.indexOf(section)
                  ].IconName
                }
                onChange={handleSectionIconNameChanged}
              />
            </div>

            <div className="mt-3">
              <h5 className="d-inline">Bullet Points</h5>
              <button
                onClick={addNewBulletPoint}
                className="ms-3 btn btn-success d-inline"
                id={`section${servicesPage.Sections.indexOf(section)}AddBtn`}
              >
                Add Bullet Point
              </button>
            </div>
            {section.BulletPoints.map((bulletPoint) => (
              <div
                key={`section${servicesPage.Sections.indexOf(
                  section
                )}BulletPoint${section.BulletPoints.indexOf(bulletPoint)}`}
              >
                <div>
                  <label
                    htmlFor={`section${servicesPage.Sections.indexOf(
                      section
                    )}BulletPoint${section.BulletPoints.indexOf(
                      bulletPoint
                    )}FormInput`}
                    className="form-label"
                  >
                    {`Bullet Point ${
                      section.BulletPoints.indexOf(bulletPoint) + 1
                    }`}
                  </label>
                  <div className="row me-1">
                    <div className="col-11">
                      <input
                        type="text"
                        className="form-control"
                        id={`section${servicesPage.Sections.indexOf(
                          section
                        )}BulletPoint${section.BulletPoints.indexOf(
                          bulletPoint
                        )}FormInput`}
                        value={
                          dbSettings.Pages[1].Sections[
                            servicesPage.Sections.indexOf(section)
                          ].BulletPoints[
                            section.BulletPoints.indexOf(bulletPoint)
                          ]
                        }
                        onChange={handleSectionBulletPointChanged}
                      />
                    </div>
                    <button
                      id={`section${servicesPage.Sections.indexOf(
                        section
                      )}BulletPoint${section.BulletPoints.indexOf(
                        bulletPoint
                      )}`}
                      onClick={handleBulletPointRemoveClicked}
                      className="col-1 btn btn-danger"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div>
        <h3 className="mt-3">{aboutMePage.Name}</h3>
        <h4>Sections</h4>
        {aboutMePage.TextSections.map((textSection) => (
          <div
            key={`aboutMeTextSection${aboutMePage.TextSections.indexOf(
              textSection
            )}`}
          >
            <h5 className="mt-3">{`Section ${
              aboutMePage.TextSections.indexOf(textSection) + 1
            }`}</h5>
            <label
              htmlFor={`textSection${aboutMePage.TextSections.indexOf(
                textSection
              )}HeaderFormInput`}
              className="form-label"
            >
              Header
            </label>
            <input
              type="text"
              className="form-control"
              id={`textSection${aboutMePage.TextSections.indexOf(
                textSection
              )}HeaderFormInput`}
              value={
                dbSettings.Pages[3].TextSections[
                  aboutMePage.TextSections.indexOf(textSection)
                ].Header
              }
              onChange={handleTextSectionHeaderChanged}
            />

            <label
              htmlFor={`textSection${aboutMePage.TextSections.indexOf(
                textSection
              )}TextFormInput`}
              className="form-label"
            >
              Text
            </label>
            <input
              type="text"
              className="form-control"
              id={`textSection${aboutMePage.TextSections.indexOf(
                textSection
              )}TextFormInput`}
              value={
                dbSettings.Pages[3].TextSections[
                  aboutMePage.TextSections.indexOf(textSection)
                ].Text
              }
              onChange={handleTextSectionTextChanged}
            />

            <label
              htmlFor={`textSection${aboutMePage.TextSections.indexOf(
                textSection
              )}IconNameFormInput`}
              className="form-label"
            >
              {"Icon (Click "}
              <a href="https://icons.getbootstrap.com/#icons" target="_blank">
                here
              </a>
              {" for the list of available icons to use)"}
            </label>
            <input
              type="text"
              className="form-control"
              id={`textSection${aboutMePage.TextSections.indexOf(
                textSection
              )}IconNameFormInput`}
              value={
                dbSettings.Pages[3].TextSections[
                  aboutMePage.TextSections.indexOf(textSection)
                ].IconName
              }
              onChange={handleTextIconNameChanged}
            />

            <FileUploader
              ref={
                aboutMePage.TextSections.indexOf(textSection) == 0
                  ? aboutMeImg1Ref
                  : aboutMePage.TextSections.indexOf(textSection) == 1
                  ? aboutMeImg2Ref
                  : aboutMeImg3Ref
              }
              id={`aboutMeImg${aboutMePage.TextSections.indexOf(
                textSection
              )}FormInput`}
              label="Image (Note: If an icon is provided, that will take precedence
                over an image.)"
            />

            <label
              htmlFor={`textSection${aboutMePage.TextSections.indexOf(
                textSection
              )}ImageCaptionFormInput`}
              className="form-label"
            >
              Image Caption
            </label>
            <input
              type="text"
              className="form-control"
              id={`textSection${aboutMePage.TextSections.indexOf(
                textSection
              )}ImageCaptionFormInput`}
              value={
                dbSettings.Pages[3].TextSections[
                  aboutMePage.TextSections.indexOf(textSection)
                ].ImageAlt
              }
              onChange={handleTextImgAltChanged}
            />
          </div>
        ))}
      </div>
      <div>
        <h3 className="mt-3">{contactPage.Name}</h3>

        <label htmlFor="contactPageIcon" className="form-label">
          {"Icon (Click "}
          <a href="https://icons.getbootstrap.com/#icons" target="_blank">
            here
          </a>
          {" for the list of available icons to use)"}
        </label>
        <input
          type="text"
          className="form-control"
          id="contactPageIcon"
          value={dbSettings.Pages[2].Icon}
          onChange={handleContactIconNameChanged}
        />

        <label htmlFor="contactPageCaption" className="form-label">
          Caption
        </label>
        <input
          type="text"
          className="form-control"
          id="contactPageCaption"
          value={dbSettings.Pages[2].Content}
          onChange={handleContactIconCaptionChanged}
        />
      </div>
    </>
  );

  useEffect(() => {
    if (!Authenticated) {
      navigate("/");
    }
  });

  return (
    <div className="bg-dark text-light">
      <ToastContainer theme="dark" />
      <form>
        <div className="sticky-top bg-black px-3 py-3">
          <div className="d-flex align-items-center justify-content-between">
            <h1 className="d-inline">Teller Development Website Settings</h1>
            <div className="d-inline">
              <button
                type="submit"
                id="submitBtn"
                onClick={saveClicked}
                className="btn btn-primary"
              >
                Save
                <span
                  id="spinner"
                  className="spinner-border text-black spinner-border-sm ms-1 d-none"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
          </div>
        </div>
        <div className="m-3">
          {navbarSection}
          {pagesSection}
        </div>
      </form>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Save Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to make changes to your website that will go live
          immediately, are you sure you want to continue?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveToDb}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Body;
