import React from "react";
import { useState } from "react";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = ({ setAuth }) => {
  const navigator = useNavigate();

  const Submit = (e) => {
    e.preventDefault();

    var authenticationData = {
      Username: userName,
      Password: password,
    };

    var authenticationDetails = new AuthenticationDetails(authenticationData);

    var poolData = {
      UserPoolId: "us-west-1_Xudw7DSk9",
      ClientId: "54i77ivu68p7e12t6e0vuft9e7",
    };

    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: userName,
      Pool: userPool,
    };

    var cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function () {
        AWS.config.region = "us-west-1";
        setAuth(true);
        navigator("/settings", { replace: true });
      },

      onFailure: function (err) {
        toast.error(err.message || JSON.stringify(err));
      },
    });
  };

  const handleUserNameChanged = (e) => {
    setUserName(e.target.value);
  };

  const handlePasswordChanged = (e) => {
    setPassword(e.target.value);
  };

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="py-3 mx-3">
      <ToastContainer theme="dark" />
      <div
        style={{ maxWidth: "600px" }}
        className="border rounded border-info p-3 mx-auto bg-dark text-light"
      >
        <h1>Login to Teller Development Website Utility</h1>
        <form>
          <label>Email</label>
          <input
            type="text"
            onChange={handleUserNameChanged}
            className="form-control"
            id="userNameTxtBx"
          />

          <label>Password</label>
          <input
            type="password"
            onChange={handlePasswordChanged}
            className="form-control"
            id="passwordTxtBx"
          />

          <button onClick={Submit} className="mt-3 btn btn-info">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
